
// 接入请求数据
import {member,status} from "../../request/api/user"

// 接入请求数据
const state ={
	
};
const getters = {

}
const mutations ={
	// login

	
};
const actions ={
	// getindex
	async getmember(content,payload){
		let {data:{data:data}} = await member({
			page:payload.current,
			pageSize:payload.pageSize,
			keyword:payload.keyword,
			platform:payload.platform
		});
		return data
	},
	async getmembers(content,payload){
		let {data:{data:data}} = await member({
			page:payload.current,
			pageSize:payload.pageSize,
			keyword:payload.keyword,
			status:1,
			platform:payload.platform
		});
		return data
	},
	// status
	async getstatus(content,payload){
		let {msg:msg} = await status({
			id:payload,
		});
		return msg
	},
};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
