

// 接入请求数据
import {upload,deletes,rename,details,crefolder,list} from "../../request/api/resources"

// 接入请求数据
const state ={
	
	
};
const getters = {
	// coutreccom(state){
	// 	return state.recommend
	// }
}
const mutations ={
	// index
	getindex(state,payload){
		state.index = payload;
	},
	
};
const actions ={
	// upload
	async getupload(content,payload){
		let {msg:msg} = await upload({
			pid:payload.pid,
			file_name:payload.file_name,
			file_suffix:payload.file_suffix,
			file:payload.file,
			sync:1
		});
		return msg
	},
	// crefolder
	async getcrefolder(content,payload){
		let {msg:msg} = await crefolder({
			pid:payload.pid,
			name:payload.name
		});
		return msg
	},
	//list
	async getlist(content,payload){
		let {data:data} = await list({
			pid:payload.pid,
		});
		return data
	},
	// deletes
	async getdeletes(content,payload){
		let {msg:msg} = await deletes({
			id:payload,
		});
		return msg
	},
};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
