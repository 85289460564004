


// 接入请求数据
import {amount,visitor,people,volume,index} from "../../request/api/home"

// 接入请求数据
const state ={
	login:""
	
};
const getters = {
	// coutreccom(state){
	// 	return state.recommend
	// }
}
const mutations ={
	// login
	getlogin(state,payload){
		state.login = payload;
	},
	
};
const actions ={
	// getamount
	async getdata(content,payload){
		console.log(payload,"传递方法是");
		let data = ""
		switch (payload){
			case 'amount':
			data = await amount({});
				break;
			case 'visitor':
			 data = await visitor({});
				break;
			case 'people':
			 data = await people({});
				break;
			case 'volume':
			 data = await volume({});
				break;
			default:
				break;
		}
		return data.data
	},
	async getindex(content,payload){
		let {data:data} = await index({})
		return data
	}

	
};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
