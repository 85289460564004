

import request from "../request"

import qs from 'qs'

// 用户列表
export const member = (data)=>{
    return request({url: "member/index",method: 'get',params: data})
}

// 拉入黑名单状态

export const status = (data)=>{
    return request({url: "member/status",method: 'get',params: data})
}