
// 首页数据
import request from "../request"
// 首页 - index
import qs from 'qs'
// 金额柱状图
export const amount = (data)=>{
    return request({url: "statistics/amount",method: 'get',params: data})
}


// 访客柱状图
export const visitor = (data)=>{
    return request({url: "statistics/visitor",method: 'get',params: data})
}

// 下单人数柱状图
export const people = (data)=>{
    return request({url: "statistics/people",method: 'get',params: data})
}

// 订单信息柱状图
export const volume = (data)=>{
    return request({url: "statistics/volume",method: 'get',params: data})
}

// 最上面数据
export const index = (data)=>{
    return request({url: "statistics/index",method: 'get',params: data})
}