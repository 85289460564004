

import request from "../../request"

import qs from 'qs'

// 店铺列表
export const bigshoplist = (data)=>{
    return request({url: "business/index",method: 'get',params: data})
}

// 创建店铺 shop/create

export const add = (data)=>{
    return request({url: "business/create",method: 'post',data: qs.stringify(data)})
}

// 修改店铺

export const edit = (data)=>{
    return request({url: "business/update",method: 'put',data: qs.stringify(data)})
}

// 店铺显示禁止
export const status = (data)=>{
    return request({url: "business/business-status",method: 'get',params: data})
}

// 获取账号列表

export const accountlist = (data)=>{
    return request({url: "business/user-list",method: 'get',params: data})
}

//创建账号

export const createaccount = (data)=>{
    return request({url: "business/user-create",method: 'post',params: data})
}

// 修改账号

export const updateaccount = (data)=>{
    return request({url: "business/user-update",method: 'put',data: qs.stringify(data)})
}

// 获取账号状态

export const accountstatus = (data)=>{
    return request({url: "business/status",method: 'get',params: data})
}

// 删除账号

export const deleteaccount = (data)=>{
    return request({url: "business/delete",method: 'DELETE',data: qs.stringify(data)})
}


