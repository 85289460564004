
/* 
 *获取vuex中的登录信息
 *
 *
 */
// import store from "../store/index.js";

// console.log(store.login,"登录信息")

/* 
 *页面路由配置
 *path:""
 *name:""
 *meta:{}路由元信息
 */

// 路由嵌套页面
let routes = [
	{
        component: "layout",
		path:"/",
		redirect:{name:"dashboard"},
		children:[
			// 仪表盘 运营数据
			{
				component: "dashboard/index",
			},
			// 小程序配置
			{
				component: "appletset/index",
				children:[
					{
						component: "appletset/navmenu",
					},
					{
						component: "appletset/activity",
					},
					{
						component: "appletset/recommend",
					},
					{
						component: "appletset/emergency",
					},
					{
						component: "appletset/classify",
					},
					{
						component: "appletset/unit",
					},
				]
			},
			// 分店配置
			{
				component: "branchshop/index",
				children:[
					{
						component: "branchshop/storeinfo",
					},
					{
						component: "branchshop/bigshop",
					}
				]
			},
			// 个人中心
			{
				component: "profile/index",
			},
			// 营销活动
			{
				component: "marketing/index",
			},
			// 客服会话系统
			{	
				path:"",
				name:"kefu",
				meta: { title: 'menu.dashboard.monitor', target: '_blank' }
				
			},
			// 商品管理
			{
				component: "goods/index",
				children:[
					{
						component:"goods/list"
					},
					{
						component:"goods/modify"
					},
				]
			},
			// 订单管理
			{
				component: "order/index",
				children:[
					{
						component:"order/orderlist"
					}
				]
			},
			// 用户管理
			{
				component: "user/index",
				children:[
					{
						component: "user/userlist",
					},
				]
			},
			// 资源管理器
			{
				component: "resources/index",
			},
			
			
		]
	},
	{
        component: "login/login",
	},
    // *重定向匹配
    {
        path: '*',
        redirect:{name:"layout"}
    }

]

// 获取路由信息方法
let getRoutes = ()=>{
	
	createRoute(routes)
	console.log(routes)
	return routes
}

// 自动生成路由
function createRoute(arr){
    // map() 方法返回一个新数组，数组中的元素为原始数组元素调用函数处理后的值
	arr.map(item=>{
		// console.log(item)
		if(!item.component){return}
		let val = getValue(item.component)
		// 自动生成name
		item.name = item.name || val.replace(/\//g,'_')
		// 自动生成path
		item.path = item.path ||`/${val}`
		// 自动生成component
		let componentfun = import(`@/views/${item.component}.vue`)
		item.component =()=>componentfun
		// item.path = item.component
		if(item.children && item.children.length>0){
			createRoute(item.children)
		}
	})
}
// 去除index
function getValue(str){
	let index = str.lastIndexOf('/')
	let val = str.substring(index+1,str.length)
	// 判断是否index结尾
	if(val==='index'){
		return str.substring(index,-1)
	}else{
		
		return str
	}
}
export default getRoutes()