

import request from "../request"

import qs from 'qs'

// 登录
export const login = (data)=>{
    return request({url: "login/login",method: 'post',data: qs.stringify(data)})
}

// 退出

export const logout = (data)=>{
    return request({url: "member/sign-out",method: 'get',params: data})
}

// 切换店铺

export const switchshop = (data)=>{
    return request({url: "member/switch-shop",method: 'post',data: qs.stringify(data)})
}