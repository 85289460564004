

import request from "../../request.js"
// 小程序前台首页内容相关 - index
import qs from 'qs'

// 获取最近单位
export const unit = (data)=>{
    return request({url: "unit/index",method: 'get',params: data})
}

// 添加单位
export const add = (data)=>{
    return request({url: "unit/create",method: 'post',data: qs.stringify(data)})
}


// 更新单位
export const update = (data)=>{
    return request({url: "unit/update",method: 'put',data: qs.stringify(data)})
}

// 删除单位
export const del = (data)=>{
    return request({url: "unit/delete",method: 'delete',data: qs.stringify(data)})
}
