

// 服务管理

import request from "../../request"

import qs from 'qs'


// 获取商品
export const goods = (data)=>{
    return request({url: "service/index",method: 'get',params: data})
}

// 添加商品
export const add = (data)=>{
    return request({url: "service/create",method: 'post',data: qs.stringify(data)})
}


// 更新商品
export const update = (data)=>{
    return request({url: "service/update",method: 'put',data: qs.stringify(data)})
}

// 删除商品
// export const del = (data)=>{
//     return request({url: "service/delete",method: 'delete',data: qs.stringify(data)})
// }

// 显示隐藏
export const status = (data)=>{
    return request({url: "service/status",method: 'get',params: data})
}