



// 接入请求数据
import {shoplist,add,edit,status,category,commission,user,bind} from "../../request/api/branchshop/storeinfo.js"

// 接入请求数据
const state ={
	
};
const getters = {

}
const mutations ={
	// login

	
};
const actions ={
	// getindex
	async getshoplist(content,payload){
		let {data:data} = await shoplist({
			page:payload.current,
			pageSize:payload.pageSize,
			keyword:payload.keyword
		});
		return data
	},

	// edit-price edit
	async getupdate(content,payload){
		let {msg:msg} = await edit({
			name:payload.name,
			receive_payment:payload.receive_payment,
		});
		return msg
	},
	// edit-price edit
	async getadd(content,payload){
		let {msg:msg} = await add({
			name:payload.name,
			receive_payment:payload.receive_payment,
		});
		return msg
	},
	// status
	async getstatus(content,payload){
		let {msg:msg} = await status({
			id:payload,
		});
		return msg
	},
	// category
	async getcategory(content,payload){
		let {data:data} = await category({
			keyword:payload.keyword,
		});
		return data
	},
	// commission
	async getcommission(content,payload){
		let {msg:msg} = await commission({
			id:payload.id,
			data:payload.data,
		});
		return msg
	},
	// user
	async getuser(content,payload){
		let {data:data} = await user({
			page:payload.current,
			pageSize:payload.pageSize,
			keyword:payload.keyword
		});
		return data
	},
	// bind
	async getbind(content,payload){
		let {msg:msg} = await bind({
			id:payload.id,
			user_id:payload.user_id,
		});
		return msg
	},
};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
