


// 接入请求数据
import {nav,add,update,del,status,sort} from "../../request/api/appletset/navmenu.js"

// 接入请求数据
const state ={
	
};
const getters = {

}
const mutations ={

	
};
const actions ={
	// getnav
	async getnav(content,payload){
		let {data:data} = await nav({
			page:payload.current,
			pageSize:payload.pageSize,
			keyword:payload.keyword,
		});
		return data
	},
	// status
	async getstatus(content,payload){
		let {msg:msg} = await status({
			id:payload,
		});
		return msg
	},
	// sort
	async getsort(content,payload){
		let {msg:msg} = await sort({
			id:payload.id,
			value:payload.value
		});
		return msg
	},
	// update
	async getupdate(content,payload){
		let {msg:msg} = await update({
			id:payload.id,
			name:payload.name,
			icon:payload.icon,
			type:payload.type,
			type_id:payload.type_id,
			banner:payload.banner
		});
		return msg
	},
	// add
	async getadd(content,payload){
		let {msg:msg} = await add({
			id:payload.id,
			name:payload.name,
			icon:payload.icon,
			type:payload.type,
			type_id:payload.type_id,
			banner:payload.banner
		});
		return msg
	},
	// del
	async getdel(content,payload){
		let {msg:msg} = await del({
			id:payload,
		});
		return msg
	},
};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
