// 封装全局的请求、拦截

import axios from 'axios'
import { message ,Spin} from 'ant-design-vue';//按需引入
import store from "../store/index.js"
import router from '../router/index.js'

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 6000, // 请求超时时间
	withCredentials:true,
	data: {userid:1}
})
request.defaults.params = {
  userid: window.localStorage.getItem('useruserid') || '',
  role: window.localStorage.getItem('userrole') || '',
  username: window.localStorage.getItem('userusername') || '',
  shopid:window.localStorage.getItem('shopid') || '',
};
  
let hide = "";
// 异常拦截处理器
const errorHandler = (error) => {
    console.log("网络请求错误拦截",error);
	 // const statuss = get(error, 'response.status');
    
	if (error.response.status ==404) {
	  message.error("请求页面不存在")
	  
	}else{
		message.error("无网络")
	}
    // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
    if (error.response) {
      const data = error.response.data
      
    }
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    // const token = storage.get(ACCESS_TOKEN)
    // // 如果 token 存在
    // // 让每个请求携带自定义 token 请根据实际情况自行修改
    // if (token) {
      // config.headers['Content-Type'] = "application/x-www-form-urlencoded"
    // }
    // config.transformRequest = [function (data) {
    //     // 做任何你想要的数据转换
    //     return data;
    //   }]
      // `params` 是即将与请求一起发送的 URL 参数
    // 必须是一个无格式对象(plain object)或 URLSearchParams 对象
    //   params: {
    //     ID: 12345
    //   },
    // console.log(config,"配置");
	store.state.loadding = true; //在请求发出之前进行一些操作
    return config
  }, errorHandler)
  
  // response interceptor
  request.interceptors.response.use((response) => {

	store.state.loadding = false;//在这里对返回的数据进行处理

    // 响应成功后的数据 状态码 2xx
    if(response.data.code == 0){
      return response.data
    }
    else{
      // 请登录 101:请登录
	  if(response.data.code == 101){
		  message.warning("请登录")
		  router.push({name:"login_login"})
	  }else{
		  message.error(response.data.msg)
		  // 如果状态码 101 跳转到登录界面
		  console.log(response.data.code);
		  return  Promise.reject(response.data.code)
	  }

      
    }
    
  }, errorHandler)
 
  export default request