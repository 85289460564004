



import request from "../../request.js"
// 小程序前台首页内容相关 - index
import qs from 'qs'


// 获取所有首页菜单导航
export const nav = (data)=>{
    return request({url: "home-category/index",method: 'get',params: data})
}

// 增加菜单
export const add = (data)=>{
    return request({url: "home-category/create",method: 'post',data: qs.stringify(data)})
}

// 更新菜单
export const update = (data)=>{
    return request({url: "home-category/update",method: 'put',data: qs.stringify(data)})
}

// 删除菜单
export const del = (data)=>{
	console.log(data)
	console.log(qs.stringify(data))
    return request({url: "home-category/delete",method: 'delete',data: qs.stringify(data)})
}

// 显示隐藏
export const status = (data)=>{
    return request({url: "home-category/status",method: 'get',params: data})
}

// 排序
export const sort = (data)=>{
    return request({url: "home-category/sort",method: 'get',params: data})
}







