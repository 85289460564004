


// 接入请求数据
import {orderlist,del,edit,ship,remarks,resive,refund} from "../../request/api/order/list.js"

// 接入请求数据
const state ={
	
};
const getters = {

}
const mutations ={
	// login

	
};
const actions ={
	// getindex
	async getorderlist(content,payload){
		let {data:data} = await orderlist({
			page:payload.current,
			pageSize:payload.pageSize,
			status:payload.status,
			keyword:payload.keyword
		});
		return data
	},
	// del
	async getdel(content,payload){
		let {msg:msg} = await del({
			orderid:payload.orderid,
		});
		return msg
	},
	// edit-price edit
	async getedit(content,payload){
		let {msg:msg} = await edit({
			orderid:payload.orderid,
			price:payload.price
		});
		return msg
	},
	// ship
	async getship(content,payload){
		let {msg:msg} = await ship({
			orderid:payload.orderid,
		});
		return msg
	},
	// addremarks
	async getaddremarks(content,payload){
		let {msg:msg} = await remarks({
			orderid:payload.orderid,
			remarks:payload.remarks
		});
		return msg
	},
	// resive
	async getaddresive(content,payload){
		let {msg:msg} = await resive({
			orderid:payload.orderid,
			amount:payload.amount
		});
		return msg
	},
	// refund
	async getaddrefund(content,payload){
		let {msg:msg} = await refund({
			orderid:payload.orderid,
			status:payload.status,
			review_remarks:payload.review_remarks,
			manner:payload.manner
		});
		return msg
	},
};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
