









// 接入请求数据
import {category,add,update,del,status,allclass} from "../../request/api/appletset/classify.js"

// 接入请求数据
const state ={
	
};
const getters = {

}
const mutations ={

	
};
const actions ={
	// getcategory
	async getcategory(content,payload){
		let {data:data} = await category({
			page:payload.current,
			pageSize:payload.pageSize,
			keyword:payload.keyword,
			type:2
		});
		return data
	},
	// allclass
	async getallclass(content,payload){
		let {data:data} = await allclass({});
		return data
	},
	// status
	async getstatus(content,payload){
		let {msg:msg} = await status({
			id:payload,
		});
		return msg
	},
	// update
	async getupdate(content,payload){
		let {msg:msg} = await update({
			id:payload.id,
			name:payload.name,
			p_id:payload.p_id,
			icon:payload.icon,

		});
		return msg
	},
	// add
	async getadd(content,payload){
		let {msg:msg} = await add({
			p_id:payload.p_id,
			name:payload.name,
			img:payload.icon,
		});
		return msg
	},
	// del
	async getdel(content,payload){
		let {msg:msg} = await del({
			id:payload,
		});
		return msg
	},
};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
