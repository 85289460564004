
import request from "../request"

import qs from 'qs'


// 获取所有服务结构树
export const commone = (data)=>{
    return request({url: "commone/index",method: 'get',params: data})
}


// 获取所有分类
export const allclass = (data)=>{
    return request({url: "category/get-all",method: 'get',params: data})
}