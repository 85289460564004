

import request from "../../request"

import qs from 'qs'

// 店铺列表
export const shoplist = (data)=>{
    return request({url: "shop/index",method: 'get',params: data})
}

// 创建店铺 shop/create

export const add = (data)=>{
    return request({url: "shop/create",method: 'post',data: qs.stringify(data)})
}

// 修改店铺

export const edit = (data)=>{
    return request({url: "shop/update",method: 'post',data: qs.stringify(data)})
}

// 显示禁止
export const status = (data)=>{
    return request({url: "shop/status",method: 'get',params: data})
}

// 获取分类

export const category = (data)=>{
    return request({url: "shop/get-category",method: 'get',params: data})
}

// 设置佣金

export const commission = (data)=>{
    return request({url: "shop/bind-category",method: 'post',params: data})
}

// 获取用户列表

export const user = (data)=>{
    return request({url: "shop/user",method: 'get',params: data})
}

// 绑定用户

export const bind = (data)=>{
    return request({url: "shop/bind-user",method: 'post',data: qs.stringify(data)})
}
