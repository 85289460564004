

// 订单管理

import request from "../../request.js"

import qs from 'qs'

// 订单列表
export const orderlist = (data)=>{
    return request({url: "order/index",method: 'get',params: data})
}

// 订单关闭

export const del = (data)=>{
    return request({url: "order/cancel",method: 'delete',data: qs.stringify(data)})
}

// 修改支付金额
export const edit = (data)=>{
    return request({url: "order/edit-price",method: 'put',data: qs.stringify(data)})
}

// 发货
export const ship = (data)=>{
    return request({url: "order/ship",method: 'get',params: data})
}

// 备注
export const remarks = (data)=>{
    return request({url: "order/remarks",method: 'post',data: qs.stringify(data)})
}

// 尾款修改
export const resive = (data)=>{
    return request({url: "order/set-payment",method: 'post',data: qs.stringify(data)})
}

// 退款
export const refund = (data)=>{
    return request({url: "order/refund",method: 'post',data: qs.stringify(data)})
}

