import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress' // progress bar
import '@/components/nprogress.less' // progress bar custom style
// 路由页面单独配置
import routes from "./routes"
Vue.use(VueRouter)

// 重复路由

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode:'history',
  routes:routes
})

//全局前置守卫
router.beforeEach((to,from,next)=>{
  NProgress.start() // start progress bar
  let token = window.localStorage.setItem('test',1234567);
  next()
  NProgress.done()
})

export default router
