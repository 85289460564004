



// 接入请求数据
import {bigshoplist,add,edit,status,accountlist,createaccount,updateaccount,accountstatus,deleteaccount} from "../../request/api/branchshop/bigshop.js"

// 接入请求数据
const state ={
	
};
const getters = {

}
const mutations ={
	// login

	
};
const actions ={
	// getindex
	async getbigshoplist(content,payload){
		let {data:data} = await bigshoplist({
			page:payload.current,
			pageSize:payload.pageSize,
			keyword:payload.keyword
		});
		return data
	},

	// edit-price edit
	async getupdate(content,payload){
		let {msg:msg} = await edit({
			id:payload.id,
			name:payload.name,
			identity_number:payload.identity_number,
			bank_card:payload.bank_card,
			banking_icense:payload.banking_icense,
			phone:payload.phone,
			store_type:payload.store_type,
			address:payload.address,
			id_photo:payload.id_photo,
			logo:payload.logo,
			theme_color:payload.theme_color,
			service_url:payload.service_url,
			appid:payload.appid,
			app_secret:payload.app_secret,
			oa_url:payload.oa_url,
		});
		return msg
	},
	// edit-price edit
	async getadd(content,payload){
		let {msg:msg} = await add({
			name:payload.name,
			identity_number:payload.identity_number,
			bank_card:payload.bank_card,
			banking_icense:payload.banking_icense,
			phone:payload.phone,
			store_type:payload.store_type,
			address:payload.address,
			id_photo:payload.id_photo,
			logo:payload.logo,
			theme_color:payload.theme_color,
			service_url:payload.service_url,
			appid:payload.appid,
			app_secret:payload.app_secret,
			oa_url:payload.oa_url,
		});
		return msg
	},
	// status
	async getstatus(content,payload){
		let {msg:msg} = await status({
			id:payload,
		});
		return msg
	},
	// createaccount
	async getcreateaccount(content,payload){
		let {msg:msg} = await createaccount({
			username:payload.username,
			password:payload.password,
			phone:payload.phone,
			shopid:payload.shopid,
		});
		return msg
	},
	// accountlist
	async getaccountlist(content,payload){
		let {data:data} = await accountlist({
			shopid:payload.shopid,
		});
		return data
	},
	// accountstatus
	async getaccountstatus(content,payload){
		let {msg:msg} = await accountstatus({
			id:payload
		});
		return msg
	},
	// updateaccount
	async getupdateaccount(content,payload){
		let {msg:msg} = await updateaccount({
			id:payload.id,
			username:payload.username,
			password:payload.password,
			phone:payload.phone,
			shopid:payload.shopid,
		});
		return msg
	},
	// deleteaccount
	async getdeleteaccount(content,payload){
		let {msg:msg} = await deleteaccount({
			id:payload.id,
			shopid:payload.shopid,
		});
		return msg
	},
};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
