









// 接入请求数据
import {goods,add,update,del,status} from "../../request/api/goods/list.js"

// 接入请求数据
const state ={
	
};
const getters = {

}
const mutations ={

	
};
const actions ={
	// getactivity
	async getgoods(content,payload){
		let {data:data} = await goods({
			page:payload.current,
			pageSize:payload.pageSize,
			keyword:payload.keyword,
		});
		return data
	},
	// status
	async getstatus(content,payload){
		let {msg:msg} = await status({
			id:payload.id,
			type:payload.type
		});
		return msg
	},
	// update
	async getupdate(content,payload){
		let {msg:msg} = await update({
			id:payload.id,
			name:payload.name,
			category_id:payload.category_id,
			show_img:payload.show_img,
			category_img:payload.category_img,
			photo_urls:payload.photo_urls,
			prices:payload.prices,
			deposit:payload.deposit,
			desc:payload.desc,
			keyword:payload.keyword,
			end_address:payload.end_address,
			template:payload.template,
			specificationAll:payload.specificationAll,
			details:payload.details,
			time:payload.time,
			icon:payload.icon,
			unit:payload.unit
		});
		return msg
	},
	// add
	async getadd(content,payload){
		let {msg:msg} = await add({
			name:payload.name,
			category_id:payload.category_id,
			show_img:payload.show_img,
			category_img:payload.category_img,
			photo_urls:payload.photo_urls,
			prices:payload.prices,
			deposit:payload.deposit,
			desc:payload.desc,
			keyword:payload.keyword,
			end_address:payload.end_address,
			template:payload.template,
			specificationAll:payload.specificationAll,
			details:payload.details,
			time:payload.time,
			icon:payload.icon,
			unit:payload.unit
		});
		return msg
	},
	// del
	async getdel(content,payload){
		let {msg:msg} = await del({
			id:payload,
		});
		return msg
	},
};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
