
// 接入请求数据
import {login,logout,switchshop} from "../../request/api/login"

// 接入请求数据
const state ={
	login:""
	
};
const getters = {
	// coutreccom(state){
	// 	return state.recommend
	// }
}
const mutations ={
	// login
	getlogin(state,payload){
		state.login = payload;
	},
	
};
const actions ={
	// getindex
	async getlogin(content,payload){
		let {data:{data:data}} = await login({
			username:payload.username,
			password:payload.password
		});

		return content.commit('getlogin',data)
	},
	// logout
	async getlogout(content,payload){
		let {msg:msg} = await logout({});
	
		return msg
	},
	// switchshop
	async getswitchshop(content,payload){
		let {msg:msg} = await switchshop({
			shopid:payload
		});
	
		return msg
	},
	
};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
