
// 接入请求数据
import {
	allclass,
	commone,
	} from "../../request/api/index"

// 接入请求数据
const state ={
	page:1,
	index:""
	
};
const getters = {
	// coutreccom(state){
	// 	return state.recommend
	// }
}
const mutations ={
	// index
	getindex(state,payload){
		state.index = payload;
	},
	
};
const actions ={
	// 所有服务结构树
	async commone(content,payload){
		let {data:{all_service:data}} = await commone({
			type:payload,
		});
		return data
	},
	// 获取商品服务分类
	async commonclass(content,payload){
		let {data:{two_category:data}} = await commone({
			type:payload,
		});
		return data
	},
	
	// // 获取商品服务分类
	async getallclass(content,payload){
		let {data:{two_category:data}} = await commone({
			type:payload,
		});
		return data
	},

};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
