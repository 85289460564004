import Vue from 'vue'
import Vuex from 'vuex'
import index from "./index/index"
import login from "./login/login"
import user from "./user/user"
import home from "./home/home"
import resources from "./resources/resources"



import appletsetnavmenu from "./appletset/navmenu.js"
import appletsetactivity from "./appletset/activity.js"
import appletsetrecommend from "./appletset/recommend.js"
import appletsetemergency from "./appletset/emergency.js"
import appletsetclassify from "./appletset/classify.js"
import appletsetunit from "./appletset/unit.js"
import goodslist from "./goods/list.js"
import orderlist from "./order/list.js"
import branchshopstoreinfo from "./branchshop/storeinfo.js"
import branchshopbigshop from "./branchshop/bigshop.js"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  loadding: false
  },
  mutations: {
	  showloadding(state, load) {
		state.loadding = load
	  }
  },
  actions: {
	  setloadding(context,load){
		context.commit("showloadding",load);
	  }
  },
  modules: {
    index,
	login,
	user,

	home,
	resources,

	
	appletsetnavmenu,
	appletsetactivity,
	appletsetrecommend,
	appletsetemergency,
	appletsetclassify,
	appletsetunit,
	goodslist,
	orderlist,
	branchshopstoreinfo,
	branchshopbigshop
  }
})
