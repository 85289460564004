


import request from "../../request.js"
// 小程序前台首页内容相关 - index
import qs from 'qs'


// 获取最近活动
export const activity = (data)=>{
    return request({url: "recent-activity/index",method: 'get',params: data})
}

// 添加活动
export const add = (data)=>{
    return request({url: "recent-activity/create",method: 'post',data: qs.stringify(data)})
}


// 更新活动
export const update = (data)=>{
    return request({url: "recent-activity/update",method: 'put',data: qs.stringify(data)})
}

// 删除活动
export const del = (data)=>{
    return request({url: "recent-activity/delete",method: 'delete',data: qs.stringify(data)})
}

// 显示隐藏
export const status = (data)=>{
    return request({url: "recent-activity/status",method: 'get',params: data})
}