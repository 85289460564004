

import request from "../request"
// 小程序前台首页内容相关 - index
import qs from 'qs'


// 图片上传
export const upload = (data)=>{
    return request({url: "resources/upload",method: 'post',data: qs.stringify(data)})
}


// 删除文件

export const deletes = (data)=>{
    return request({url: "resources/delete",method: 'delete',data:qs.stringify(data)})
}

// 修改文件名

export const rename = (data)=>{
    return request({url: "resources/edit-name",method: 'put',data: qs.stringify(data)})
}


// 文件详情

export const details = (data)=>{
    return request({url: "resources/details",method: 'get',params: data})
}

// 创建文件夹

export const crefolder = (data)=>{
    return request({url: "resources/create-folder",method: 'post',data: qs.stringify(data)})
}


// 资源列表


export const list = (data)=>{
    return request({url: "resources/index",method: 'get',params: data})
}