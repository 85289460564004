




// 接入请求数据
import {activity,add,update,del,status} from "../../request/api/appletset/activity.js"

// 接入请求数据
const state ={
	
};
const getters = {

}
const mutations ={

	
};
const actions ={
	// getactivity
	async getactivity(content,payload){
		let {data:data} = await activity({
			page:payload.current,
			pageSize:payload.pageSize,
			keyword:payload.keyword,
			type:1
		});
		return data
	},
	// status
	async getstatus(content,payload){
		let {msg:msg} = await status({
			id:payload,
		});
		return msg
	},
	// update
	async getupdate(content,payload){
		let {msg:msg} = await update({
			id:payload.id,
			name:payload.name,
			service_id:payload.service_id,
			icon:payload.icon,

		});
		return msg
	},
	// add
	async getadd(content,payload){
		let {msg:msg} = await add({
			type:1,
			service_id:payload.service_id,
			name:payload.name,
			icon:payload.icon,
		});
		return msg
	},
	// del
	async getdel(content,payload){
		let {msg:msg} = await del({
			id:payload,
		});
		return msg
	},
};

export default {
	namespaced: true,   //使用命名空间

	state,
	
	getters,

	mutations,

	actions
}
