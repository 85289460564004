



import request from "../../request.js"
// 小程序前台首页内容相关 - index
import qs from 'qs'


// 获取最近分类
export const category = (data)=>{
    return request({url: "category/index",method: 'get',params: data})
}

// 添加分类
export const add = (data)=>{
    return request({url: "category/create",method: 'post',data: qs.stringify(data)})
}


// 更新分类
export const update = (data)=>{
    return request({url: "category/update",method: 'put',data: qs.stringify(data)})
}

// 删除分类
// export const del = (data)=>{
//     return request({url: "recent-activity/delete",method: 'delete',data: qs.stringify(data)})
// }

// 显示隐藏
export const status = (data)=>{
    return request({url: "category/status",method: 'get',params: data})
}

// 获取所有分类
export const allclass = (data)=>{
    return request({url: "category/get-all",method: 'get',params: data})
}